<template>
  <div class="home">
    <Header :show-search="false" :showAreaPicker="false" :show-old="true" />
    <Swipe :bannerList="bannerList" isIndex imgKey="banner" />
    <div class="content">
      <div class="film" @click="goPath('/ruralFilm')">
        <b>公益放映</b>
      </div>
      <div class="right">
        <!--
        <div class="work-hall flex" @click="goPath('/work')">
          <b>办事大厅</b>
          <span>零跑腿一站通</span>
        </div>
        -->
        <div class="information flex" @click="goPath('/info')">
          <b>信息公开</b>
          <span>服务透明化</span>
        </div>
      </div>
    </div>

    <div class="film-tools" @click="goPath('/filmAssistant')">
      <b>影院助手</b>
      <span>更高服务效率</span>
    </div>
    <!--    浙文创应用App跳转入口-->
    <!-- <div class="film-tools film-tools-zwc" @click="goZjApp()"> -->
    <!--      <b>浙文创应用</b>-->
    <!--      <span>一站式服务</span>-->
    <!-- </div> -->
    <div class="server footer">
      <div>本服务由浙江政务服务网、省委宣传部电影处提供</div>
      <div>
        服务咨询热线：<span style="color: blue" @click="onCallTap()">0571一87053357</span>
      </div>
    </div>
    <!-- <Tabbar /> -->
  </div>
</template>

<script>
import Header from '@/components/header'
import Swipe from '@/components/swipe'
// import Tabbar from '@/components/tabbar'
import { api } from '@/api/index'
import { zjApi } from '@/api/index'
import { SetPvAndUv } from '@/mixins/PvAndUv'
import { Toast } from 'vant'
import { debug } from "prettier/doc";
// import { requestImgUrl } from "@/utils/util";
import config from '@/config'
import common from '@/utils/common'
import store from '@/store'

export default {
  name: 'home',
  components: {
    Swipe,
    // Tabbar,
    Header,
  },
  mixins: [SetPvAndUv],
  data() {
    return {
      bannerList: [],
      rules: {
        cinemaManage: true,
      },
    }
  },
  mounted() {
    const _this = this
    if (!this.$store.state.token) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      if (process.env.NODE_ENV === 'development') {
        this.$router.replace('/login')
        return
      } else {
        // 生产环境重新加载应用
        // this.$router.replace('/auth')
        ZWJSBridge.openLink({
          type: 'reload',
        })
        return
      }
    }
    this.getBannerList()
    
  },
  // 监听store中token的变化
  watch: {
    '$store.state.token': function () {
      const _this = this
      _this.getBannerList()
      // 进行一次埋点信息提交
      zwlog.onReady(function () {
        zwlog.sendPV({
          miniAppId: config.appid,
          pageName: _this.$route.name+'-首页',
          t2: (_this.pageTime.deactived - _this.pageTime.mounted) / 1000,
          t0: (_this.pageTime.deactived - _this.pageTime.created) / 1000,
          log_status: store.state.token ? '02' : '01',
          _user_nick: store.state.userInfo.username,
          _user_id: _this.$store.state.userInfo.userid,
        })
      })
    },
  },
  methods: {
    getBannerList() {
      api.getBanner({ bannerUse: 1 }).then((res) => {
        this.bannerList = res.slice(0, 5)
      })
    },
    goPath(url) {
      switch (url) {
        case '/filmAssistant':
          if (this.rules.cinemaManage) {
            this.$router.push(url)
          } else {
            Toast('您当前无可管理的影院，如您是影院运营者请拨打咨询热线！')
          }
          break
        default:
          this.$router.push(url)
          break
      }
    },
    //服务咨询
    onCallTap() {
      ZWJSBridge.phoneCall({
        corpId: '057187053357',
      })
        .then((result) => {
          console.log(result)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    showCinemaList() {
      this.$refs.cinemaListModel.show()
    },

    //浙文创应用跳转
    goZjApp() {
      // window.location.href = "https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2001934568/lastTest/index.html";
      ZWJSBridge.openLink({
        url: 'https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2001934568/lastTest/index.html',
      })
    },
    selectCinema(cinema) {
      console.log(cinema)
      this.$router.push({
        path: '/filmAssistant',
        query: {
          ...cinema,
        },
      })
    }
  },
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 16px 80px;

  .content {
    //margin-top: 11px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .film {
      width: 50%;
      height: 232px;
      background: url('../../assets/home/ncBg.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
      display: flex;
      align-items: flex-end;
      padding: 0 0 27px 20px;
      box-sizing: border-box;

      b {
        display: block;
        font-size: 24px;
        color: #27538c;
      }
    }

    .right {
      width: 50%;
      height: 232px;

      .flex {
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
      }

      .information {
        margin-top: 8px;
        background: url('../../assets/home/xxgkBg.png') no-repeat center center;
        background-size: cover;
        /* 或者使用 contain */
        border-radius: 12px;
        /* 添加圆角 */
        overflow: hidden;
        /* 确保内容不会溢出圆角 */
        transform: translateY(15px);

        b {
          font-size: 24px;
          color: #816c3e;
          margin-left: 20px;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #816c3e;
          margin-left: 20px;
        }
      }
    }
  }

  .film-tools {
    margin-top: 0.2rem;
    //border:1px solid #816c3e;
    border-radius: 10px;
    height: 130px;
    display: flex;
    background: url('../../assets/home/cm.png') no-repeat;
    background-size: 100% 100%;
    flex-direction: column;

    &-zwc {
      background: url('../../assets/home/zwc.png') no-repeat center center;
      background-size: 100%;
      //border: #816c3e 1px solid;
    }

    >b {
      font-size: 24px;
      color: #416e6f;
      margin-left: 20px;
      padding: 0.6rem 0.2rem;
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: #3f706b;
      margin-left: 20px;
      padding: 0 0.2rem;
    }
  }
}

.server {
  width: 100%;
  text-align: center;
  color: gray;
  font-size: 12px;
  line-height: 2;
  margin-top: 30px;

}
</style>
